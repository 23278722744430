<template>
  <div class="navbar">
    <div class="content">
      <div class="logo">
        <router-link to="/"><b>TENNISCLUB DÖRVERDEN</b></router-link>
      </div>
      <div class="nav-items">
        <router-link class="route" to="/ueber">Über uns</router-link>
        <router-link class="route" to="/gallerie">Unsere Anlage</router-link>
        <router-link class="route" to="/mitgliedwerden"
          >Mitgliedsbeiträge</router-link
        >
        <router-link class="route" to="/kontakt">Kontakt</router-link>
        <router-link class="route" to="/impressum"
          >Impressum & Datenschutz</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarDesktop",
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: white;
}
.navbar {
  padding: 25px 30px 12px 30px;
  background: forestgreen;
}
.logo {
  font-size: 24px;
}
.route {
  font-size: 22px;
  border-radius: 4px;
  padding: 0 5px 0 5px;
  margin: 0 5px 0 5px;
  height: 200px;
}
.route:hover {
  background-color: #82b74b;
  transform: rotate(90deg);
}
.route:active {
  background-color: #86af49;
}
</style>
