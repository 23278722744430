<script>
export default {
  name: "vue-title",
  props: ["title"],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title =
          "Tennisclub Dörverden" + (this.title ? " - " + this.title : "");
      },
    },
  },
};
</script>
