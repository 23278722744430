<template>
  <div>
    <h2>Werbeaktion im Jahre 2024:</h2>
    <b>
      Aktive Tennisspieler, die im Jahre 2024 unserem Verein beitreten, zahlen
      einmalig für 2024 keine Mitgliedsbeiträge. Es gelten lediglich die
      Bestimmungen über den jährlich zu leistenden Arbeitsdienst.
    </b>
  </div>
</template>

<script>
export default {
  name: "WerbeAktion",
};
</script>

<style scoped>
div {
  color: red;
  font-size: 18px;
  outline: solid red 1px;
}
</style>
