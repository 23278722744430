<template>
  <div id="container">
    <text>Tennisclub Dörverden e.V.</text> <br />
    <text>tennisclub-doerverden@web.de</text> <br />
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#container {
  margin-top: auto;
  padding: 25px;
  background-color: forestgreen;
  font-size: 16px;
  color: white;
  text-align: right;
  left: 0;
  right: 0;
}
</style>
