<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>
<template>
  <div class="navbar">
    <div class="content">
      <a class="menu" @click="toggleOverlay"><b>☰</b></a>
      <div class="logo">
        <router-link to="/"><b>TENNISCLUB DÖRVERDEN</b></router-link>
      </div>
      <div id="overlay" class="navbar">
        <a class="menu" @click="toggleOverlay"><b>-</b></a>
        <ul>
          <li>
            <router-link class="route" to="/" @click="hideOverlay()"
              ><link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
              /><span class="material-symbols-outlined"> home </span>
              Startseite</router-link
            >
          </li>
          <li>
            <router-link class="route" to="/ueber" @click="hideOverlay()"
              ><span class="material-symbols-outlined"> info </span> Über
              uns</router-link
            >
          </li>
          <li>
            <router-link class="route" to="/gallerie" @click="hideOverlay()"
              ><span class="material-symbols-outlined"> image </span> Unsere
              Anlage</router-link
            >
          </li>
          <li>
            <router-link
              class="route"
              to="/mitgliedwerden"
              @click="hideOverlay()"
              ><span class="material-symbols-outlined"> group </span>
              Mitgliedsbeiträge</router-link
            >
          </li>
          <li>
            <router-link class="route" to="/kontakt" @click="hideOverlay()"
              ><span class="material-symbols-outlined"> contact_support </span>
              Kontakt</router-link
            >
          </li>
          <li>
            <router-link class="route" to="/impressum" @click="hideOverlay()"
              ><span class="material-symbols-outlined"> description </span>
              Impressum & Datenschutz</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarMobile",
  data() {
    return {
      overlay: false,
    };
  },
  methods: {
    toggleOverlay() {
      if (this.overlay) {
        this.hideOverlay();
      } else {
        this.showOverlay();
      }
    },
    showOverlay() {
      const overlay = document.getElementById("overlay");
      document
        .getElementsByClassName("menu")[0]
        .setAttribute("style", "display:none;");
      overlay.setAttribute("style", "display:block;");
      overlay.style.display = "block";
      for (let i = 0; i <= 100; i++) {
        setTimeout(() => {
          overlay.setAttribute("style", "display:block;width:" + i + "px");
          overlay.style.width = i + "%";
        }, i * 2);
      }
      this.overlay = true;
    },
    hideOverlay() {
      const overlay = document.getElementById("overlay");
      for (let i = 0; i <= 100; i++) {
        setTimeout(() => {
          overlay.setAttribute(
            "style",
            "display:block;width:" + (100 - i) + "px"
          );
          overlay.style.width = 100 - i + "%";
        }, i * 2);
      }
      setTimeout(() => {
        overlay.setAttribute("style", "display:none;");
        overlay.style.display = "none";
        document
          .getElementsByClassName("menu")[0]
          .setAttribute("style", "display:block;");
      }, 100 * 2);
      this.overlay = false;
    },
  },
};
</script>

<style scoped>
#overlay .menu {
  font-size: 40px;
}
#overlay {
  display: none;
  position: fixed; /* Sit on top of the page content */
  width: 0; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
}
li {
  font-size: 28px;
  text-decoration: none;
  list-style: none;
  color: white;
  margin-top: 10px;
  overflow: hidden;
  min-width: 100vw;
}

.navbar {
  z-index: 100;
}

.logo {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  font-size: 20px;
}
</style>
