<template>
  <div class="container">
    <VueTitle title="Kontakt"></VueTitle>
    <h1>Kontaktiere uns</h1>
    <div class="content">
      Tennisclub Dörverden e.V.<br />
      Diensthoper Str. 119<br />
      27313 Dörverden<br />
      <br />
      Postanschrift:<br />
      Thorner Weg 5<br />
      27313 Dörverden<br />
      <br />
      Ansprechpartner für Interessierte:
      <ul>
        <li>Für Tennis: Lars Hermann, Tel. 01743064666</li>
        <li>Für Boule: Irmgard Kern, Tel. 042341525</li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueTitle from "@/components/VueTitle";

export default {
  name: "Contact",
  components: { VueTitle },
};
</script>

<style scoped>
.container {
  margin: 100px 10% 10%;
}
.content {
  display: block;
  padding-left: 20px;
  border-left: lightgray 5px solid;
}
</style>
