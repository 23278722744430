<template>
  <div class="page">
    <VueTitle title="Mitgliedsbeiträge"></VueTitle>
    <h1>Beiträge</h1>
    <div class="card">
      <WerbeAktion></WerbeAktion>
    </div>
    <br /><br /><br />
    <div class="card">
      <table>
        <tr>
          <th>Jahresbeitrag</th>
          <th>Euro (€)</th>
        </tr>
        <tr>
          <td>Erwachsene ab 25 Jahre</td>
          <td>120,00</td>
        </tr>
        <tr>
          <td>Jugendliche und junge Erwachsene unter 25 Jahren</td>
          <td>60,00</td>
        </tr>
        <tr>
          <td>Lebensgemeinschaften</td>
          <td>200,00</td>
        </tr>
        <tr>
          <td>Familienbeitrag 1 (1 Erwachsener und Jugendliche unter 25 Jahren)</td>
          <td>160,00</td>
        </tr>
        <tr>
          <td>Familienbeitrag 2 (2 Erwachsene und Jugendliche unter 25 Jahren)</td>
          <td>250,00</td>
        </tr>
        <tr>
          <td>Passive Mitglieder</td>
          <td>25,00</td>
        </tr>
        <tr>
          <td>Gastspieler pro Spiel (maximal 3x jährlich)</td>
          <td>10,00</td>
        </tr>
        <tr>
          <td>Boulespieler</td>
          <td>25,00</td>
        </tr>
      </table>
    </div>

    <br />
    <br />
    <div class="card">
      <div class="info">
        Aktive Vereinsmitglieder des TSV Dörverden e. V. oder des SC Weser e. V.
        Barme zahlen einen vergünstigten Jahresbeitrag gemäß den geschlossenen
        Kooperationsvereinbarungen.
        <br /><br />
        Bei Eintritt ab 15.08. jeden Jahres ist die Hälfte des Jahresbeitrages
        zu zahlen. Der Jahresbeitrag ist grundsätzlich zum 15.04. fällig und
        wird im Lastschriftverfahren eingezogen. Rücklastschriften sowie
        Zahlungen nach dem 01.07. jeden Jahres werden mit einem
        Verwaltungskostenbeitrag von 8,00 € belegt.
        <br /><br />
        Jährlich sind von aktiven Tennisspielern ab 16 Jahren jährlich
        mindestens 6 Arbeitsstunden zu leisten. Werden diese Arbeitsstunden
        nicht abgeleistet, sind ersatzweise 15,00 € pro Arbeitsstunde an den
        Verein zu zahlen. Der sich aus nicht geleisteten Arbeitsstunden ergebene
        Betrag wird grundsätzlich zum 01.11. jeden Jahres fällig und im
        Lastschriftverfahren eingezogen.
        <br /><br />
        Die Boulespieler pflegen die Bouleanlage nebst umliegenden Flächen
        während des gesamten Kalenderjahres eigenverantwortlich. Ziel der
        Außenanlagenpflege ist es, dass die Anlage stets einen sauberen und
        gepflegten Eindruck vermittelt.
        <br /><br />
        Diese Beitragsordnung gilt ab dem 01.01.2023.
      </div>
    </div>
  </div>
</template>

<script>
import WerbeAktion from "@/components/WerbeAktion";
import VueTitle from "@/components/VueTitle";
export default {
  name: "Join",
  components: { VueTitle, WerbeAktion },
};
</script>

<style scoped>
@media only screen and (max-width: 780px) {
  table {
    margin-left: auto;
    margin-right: auto;
    float: none;
    margin-bottom: 20px;
  }
  .page {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media only screen and (min-width: 780px) {
  table {
    /*float: right;*/
    /*margin-left: 20px;*/
  }
  .page {
    margin-top: 2%;
    margin-left: 12%;
    margin-right: 12%;
  }
}
table,
th,
td {
  border: solid 1px;
}

.page {
  margin-top: 80px;
  padding-bottom: 10%;
}
.card {
  border-left: lightgray 5px solid;
  padding-left: 20px;
}
</style>
