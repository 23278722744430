<template>
  <div class="home">
    <VueTitle title=""></VueTitle>
    <div class="parallax">
      <div class="text-container">
        <h1><text>Tennisclub Dörverden e.V.</text></h1>
      </div>
    </div>
    <div class="mid-content">
      <h1 class="mid-content-header">
        <b> Willkommen auf der Website des Tennisclubs Dörverden!</b>
      </h1>
      <WerbeAktion></WerbeAktion>
    </div>
  </div>
</template>

<script>
import VueTitle from "@/components/VueTitle";
import WerbeAktion from "@/components/WerbeAktion";
export default {
  name: "Home",
  components: { WerbeAktion, VueTitle },
};
</script>

<style scoped>
body,
html {
  height: 100%;
}

.mid-content {
  margin: 30px;
}

.mid-content-header {
  margin: 15px auto 15px auto;
  text-align: center;
  font-size: 25px;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.parallax-container {
  overflow: hidden;
  height: 90vh;
}

.parallax {
  /* The image used */
  background-image: url("../assets/gallery/home-image.jpeg");
  /*filter: blur(5px);*/

  /* Set a specific height */
  /*min-height: 610px;*/
  height: 120vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  top: 0;
  z-index: -1;
  scale: 1;
  /*position: fixed;*/
}

.text-container h1 {
  padding-top: 100px;
  text-align: center;
  color: white;
  margin: 0;
}
.text-container h1 text {
  font-size: 6vw;
  background-color: rgba(0, 0, 0, 30%);
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  user-select: none;
}
</style>
