<template>
  <Navbar></Navbar>
  <div id="app">
    <router-view />
  </div>
  <Footer class="foot"></Footer>
</template>

<style>
body {
  margin: 0;
  font-size: 23px;
  font-family: Calibri, serif;
}
#app {
  margin-bottom: 100px;
  margin-top: 60px;
}

.foot {
  height: 40px;
  bottom: 0;
  position: fixed;
}
</style>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {
    Footer,
    Navbar,
  },
};
</script>
