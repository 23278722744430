<template>
  <div id="global-navbar">
    <NavbarDesktop class="desktop"></NavbarDesktop>
    <NavbarMobile class="mobile"></NavbarMobile>
  </div>
  <!--  <div id="navbar-placeholder"></div>-->
</template>

<script>
import NavbarDesktop from "@/components/NavbarDesktop";
import NavbarMobile from "@/components/NavbarMobile";
export default {
  name: "Navbar",
  components: { NavbarMobile, NavbarDesktop },
};
</script>

<style>
#global-navbar {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 100;
}

#global-navbar a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  user-select: none;
}
.navbar {
  padding: 25px 30px 12px 30px;
  background: forestgreen;
}
.logo {
  font-size: 24px;
}
.content {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .mobile {
    display: inherit;
  }
  .desktop {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: inherit;
  }
}
</style>
